<template>
  <div >
    <div class="md-layout">
      <div class="md-layout-item md-size-11  md-xsmall-size-40 md-xsmall-hide">
        <h4 class="md-headline">Contribuyentes</h4>
      </div>
      <div class="md-layout-item md-size-60  md-xsmall-size-60">
        <md-field md-clearable @md-clear="handlerClearInput">
          <label>Búscar</label>
          <span class="md-prefix"><md-icon>search</md-icon></span>
          <md-input ref="input_token" v-model="token" @keyup.enter='list_company'></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <br>
        <md-button @click.prevent="showDialog=true">
          <md-icon >filter_list</md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="go_to(`/company`)">
          <md-icon>add</md-icon>
        </md-button>
      </div>
    </div>
    <md-card class="md-accent" v-for="(item, index) in companies" :key="index">
       <md-card-header>
         <md-card-header-text>
           <div class="md-title" style="font-size: 17px;">{{item.name}}</div>
           <div class="md-subhead">{{item.AfiliacionIVA}}</div>
           <div class="md-subhead">{{parseFloat(item.porcentaje_IVA).toFixed(0)  }}  %</div>
         </md-card-header-text>
         <md-card-media md-small>
           <img v-if="item.logo" :src="'data:image/png;base64,' +item.logo_url" alt="logo">
         </md-card-media>
         <md-menu md-size="small" md-direction="bottom-end">
             <md-button class="md-icon-button" md-menu-trigger>
               <md-icon>more_vert</md-icon>
             </md-button>
             <md-menu-content >
               <md-menu-item @click="go_to(`/company/${item.id}`)">
                  <span>Editar</span>
                  <md-icon>edit</md-icon>
               </md-menu-item>
             </md-menu-content>
        </md-menu>
      </md-card-header>
      <md-card-actions >
        <md-button
        class="md-primary md-raised" @click="set_company(item)">Seleccionar</md-button>
      </md-card-actions>
    </md-card>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-empty-state v-if="companies.length === 0"
      md-icon="free_breakfast"
      md-label="Crear la primer contribuyente"
      md-description="Aún no hay contribuyentes registrados, crea el primero.">
    </md-empty-state>
    <md-button
      @click="go_to(`/company`)" class="md-primary md-raised">Agregar contribuyente</md-button>

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Filtros</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name">Nombre</label>
              <md-input @keyup.enter="list_company()"
              v-model="filters.name" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="razon_social">Razon social</label>
              <md-input @keyup.enter="list_company()"
              v-model="filters.razon_social" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="afilicionIVA">Afiliación IVA</label>
              <md-select name="afiliacionIVA"
              id="afiliacionIVA" @md-selected="list_company()"
               v-model="filters.afiliacionIVA"  :disabled="sending_request">
                <md-option >TODAS</md-option>
                <md-option value="GEN">GENERAL</md-option>
                <md-option value="PEQ">PEQUEÑO CONTRIBUYENTE</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="nit">NIT</label>
              <md-input @keyup.enter="list_company()"
              v-model="filters.nit" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="phone">Teléfono</label>
              <md-input @keyup.enter="list_company()"
              v-model="filters.phone" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="limit">Registros a mostrar</label>
              <md-input @keyup.enter="list_company()"
              v-model="filters.limit" type="number" :disabled="sending_request" />
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list_company()">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /.orders -->
  </div>
</template>

<script>

import moment from 'moment';

export default {
  created() {
    this.$store.commit('SET_LAYOUT', 'default');
    const filters = this.get_data_storage(this.name_storage_filter);
    if (filters) {
      this.filters = filters;
    }
    this.list_company();
    document.addEventListener('keyup', (e) => {
      if (e.key === 'f4' || e.keyCode === 115) {
        this.$refs.input_token.$el.focus();
      }
    });
  },
  data() {
    return {
      sending_request: false,
      companies: [],
      token: '',
      filters: {
        status: true,
        limit: 30,
      },
      showDialog: false,
      url: 'company/',
      name_storage_filter: 'filters_companies',
    };
  },
  name: 'Home',
  methods: {
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    reset_filters() {
      this.filters = { limit: 30 };
      this.remove_storage(this.name_storage_filter);
      this.list_company();
    },
    handlerClearInput() {
      this.token = '';
      this.list_company();
      this.$refs.input_token.$el.focus();
    },
    go_to(url) {
      this.$router.push(url);
    },
    set_company(obj) {
      this.$store.dispatch('set_company', { companyObj: obj });
      this.$store.dispatch('set_store', { storeObj: null });
      this.go_to(`/stores/${obj.id}`);
    },
    handlerError(error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data;
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', `${key} : ${value}`, 'error'),
        );
        return false;
      }
      this.$swal.fire('Error!', error.response.data, 'error');
      return false;
    },
    get_settings() {
      const payload = {};
      const self = this;
      self.sendRequest(payload, 'settings/').then((data) => {
        self.to_course_array(data.data);
        self.to_data_array(data.data);
        const settings = data.data;
        self.$store.dispatch('set_data_settgins', { settings });
        self.save_storage('settings', settings);
        self.barChart();
      }).catch((error) => {
        if (error.response.status === 401) {
          console.log(error.response.status, 'oin');
          this.Logout();
        }
        this.handlerError(error);
      });
    },
    Logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      }).catch((error) => {
        console.error(error);
        this.$swal.fire('Error!', 'No fue posible finalizar la sesión.', 'error');
      });
    },
    list_company() {
      const payload = {};
      if (this.token.length > 2) {
        payload.token = this.token;
        payload.filter_company = 'True';
      }
      if (Object.keys(this.filters).length > 0) {
        Object.assign(payload, this.filters);
      }
      /* no-prototype-builtins */
      if (Object.prototype.hasOwnProperty.call(payload, 'status')) {
        if (payload.status) {
          payload.status = 'True';
        }
        if (!payload.status) {
          payload.status = 'False';
        }
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'afiliacionIVA')) {
        if (!payload.afiliacionIVA) {
          delete payload.afiliacionIVA;
        }
      }

      if (this.sending_request) return false;
      this.sending_request = true;
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.companies = data.data;
        this.sending_request = false;
        this.save_storage(this.name_storage_filter, this.filters);
        this.showDialog = false;
      }).catch((error) => {
        this.sending_request = false;
        this.handlerError(error);
        console.log(error.response.data);
      });
      return null;
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
  },
  filters: {
    status_to_text(value) {
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD', 'es').format('ll');
    },
  },
  computed: {
    settings() {
      return this.$store.state.settings_obj;
    },
    /* eslint no-param-reassign: "error" */
    group_by_dia() {
      const group = this.horario_obj.detalle.reduce((r, a) => {
        r[a.orden_dia] = [...r[a.orden_dia] || [], a];
        return r;
      }, {});
      return group;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "~vue-material/dist/theme/engine"; // Import the theme engine
  @include md-register-theme("green-card", (
     primary: md-get-palette-color(green, 400)
   ));
  @import "~vue-material/dist/base/theme";
  @import "~vue-material/dist/components/MdCard/theme";
  @include md-register-theme("bluegrey-card", (
     primary: md-get-palette-color(bluegrey, 400)
   ));
  @import "~vue-material/dist/base/theme";
  @import "~vue-material/dist/components/MdCard/theme";
  @include md-register-theme("black-card", (
     primary: md-get-palette-color(deeppurple, 200)
   ));
  @import "~vue-material/dist/base/theme";
  @import "~vue-material/dist/components/MdCard/theme";
  @include md-register-theme("blue-button", (
     primary: md-get-palette-color(blue, 400)
   ))
  @import "~vue-material/dist/base/theme";
  @import "~vue-material/dist/components/MdButton/theme";
</style>
<style lang="css" scoped>
    #weatherWidget .currentDesc {
        color: #ffffff!important;
    }
    .traffic-chart {
        min-height: 335px;
    }
    #flotPie1  {
        height: 150px;
    }
    #flotPie1 td {
        padding:3px;
    }
    #flotPie1 table {
        top: 20px!important;
        right: -10px!important;
    }
    .chart-container {
        display: table;
        min-width: 270px ;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #flotLine5  {
          height: 105px;
    }

    #flotBarChart {
        height: 150px;
    }
    #cellPaiChart{
        height: 160px;
    }
    .on-over {
      cursor: pointer;
    }
  .md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
  .md-app {
    border: 1px solid rgba(#000, .12);
  }
</style>
